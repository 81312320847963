import {
  Flex,
  Link,
  Stack,
  Box,
  Menu,
  MenuButton,
  Text,
  IconButton,
  Image,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  VStack,
  DrawerHeader,
} from "@chakra-ui/react";
import { useRef, useEffect, useState } from "react";

import { useEmailList } from "../hooks/data/email-list";

import { AdminManageMenu } from "./manage-menu";
import StaffManageMenus from "./staff-manage-menus";
import PricingMenu from "./pricing-menu";
import EmailMenus from "./email-menus";
import RecordMenus from "./records-menus";
import TrainingMenus from "./training-menus";
import MenuIcon from "./menu-icon";
import React from "react";
import { auth } from "../utils/auth";

const handleLogout = () => {
  // Clear session token or any other necessary logout actions
  localStorage.removeItem("userInfo");
  // Redirect to the home page
  window.location.href = "/";
};

const LinkText = ({ children, color, ...props }) => {
  const colorValue = color || "white";
  return (
    <Text
      as="span"
      fontSize={["lg", "lg", "md"]}
      fontWeight="bold"
      color={colorValue}
      {...props}
    >
      {children}
    </Text>
  );
};

const AdminClimsNavigationBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userInfo, setUserInfo] = useState(null);
  const [CryoEmailList, setCryoEmailList] = useState();
  const btnRef = useRef();

  const { emailList } = useEmailList();

  useEffect(() => {
    const curUserInfo = auth();
    setUserInfo(curUserInfo);
  }, []);

  useEffect(() => {
    if (emailList) {
      // Get the email addresses from the email list
      const CryoEmailList = emailList.find((obj) => obj.PURPOSE === "Cryo EM");
      const emails = Object.values(JSON.parse(CryoEmailList.EMAILS));
      setCryoEmailList(emails);
    }
  }, [emailList]);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      // wrap="wrap"
      // minWidth='max-content'
      padding="1.5rem"
      w="100%"
      mb={0}
      p={5}
      position="sticky"
      bg="black"
      borderTopStyle="solid"
      borderTopColor="red.600"
      borderTopWidth={[0, 0, 2, 2]}
      flexDirection={{ base: "row", md: "column" }}
      zIndex={1000}
    >
      {/* Hamburger Icon - Display on small screens */}
      <Box
        display={{ base: "block", md: "none" }}
        position="relative"
        pl={{ base: "1.5rem", md: "2rem" }}
      >
        <IconButton
          aria-label={isOpen ? "Close" : "Open"}
          variant="ghost"
          onClick={onOpen}
          ref={btnRef}
          icon={<MenuIcon isOpened={isOpen} />}
          size="lg"
          left={1}
        />
      </Box>
      <Box
        // flex={{ base: 1, md: "initial" }}
        // textAlign={{ base: "center", md: "left" }}
        // pr={5}
        textAlign="center"
        w={{ base: "100%", md: "auto" }}
        mb={{ base: 4, md: 4 }}
      >
        <Link href="https://usc.edu" isExternal>
          <Image
            src={`${process.env.REACT_APP_IMAGES_URL}/home_images/USC_CNI_with_shield_large_gold.png`}
            alt="University of Southern California"
            width="100%"
            height="62px"
            objectFit={{ base: "contain", md: "contain" }}
          />
        </Link>
      </Box>

      <Box
        display={{ base: "none", md: "block" }}
        // flexBasis={{ base: "100%", md: "auto" }}
        w={{ md: "auto", lg: "100%" }}
        textAlign="center"
      >
        <Stack
          spacing={[10, 10, 16, 16]}
          align="center"
          justify="center"
          direction={{ base: "column", md: "row" }}
          pt={[4, 4, 0, 0]}
        >
          <Menu preventOverflow="true">
            <MenuButton
              color="white"
              as="a"
              href="/calendar"
              style={{
                paddingLeft: 20,
                textDecoration: "none",
                fontSize: "10",
                color: "inherit",
                "&:hover": { textDecoration: "none", color: "inherit" },
              }}
            >
              {" "}
              {userInfo && userInfo.class === 1 && (
                <LinkText>Admin: {userInfo.username}</LinkText>
              )}
              {userInfo && userInfo.class === 7 && (
                <LinkText>Financial: {userInfo.username}</LinkText>
              )}
              {userInfo && userInfo.class === 2 && (
                <LinkText>Staff: {userInfo.username}</LinkText>
              )}
            </MenuButton>
          </Menu>

          {userInfo && (userInfo.class === 1 || userInfo.class === 7) && (
            <AdminManageMenu classlevel={userInfo.class} />
          )}
          {userInfo && userInfo.class === 2 && (
            <StaffManageMenus
              Customer={userInfo}
              CryoCommitteeList={CryoEmailList}
            />
          )}
          {userInfo && <RecordMenus classLevel={userInfo.class} />}
          {userInfo && <EmailMenus classlevel={userInfo.class} />}

          <PricingMenu />
          <TrainingMenus />

          <Menu preventOverflow="true">
            <MenuButton
              color="white"
              as="a"
              href="/calendar"
              style={{
                paddingLeft: 20,
                textDecoration: "none",
                fontSize: "10",
                color: "inherit",
                "&:hover": { textDecoration: "none", color: "inherit" },
              }}
            >
              {" "}
              <LinkText>Calendar</LinkText>
            </MenuButton>

            <MenuButton
              color="white"
              as="a"
              href="/online"
              style={{
                paddingLeft: 20,
                textDecoration: "none",
                fontSize: "10",
                color: "inherit",
                "&:hover": { textDecoration: "none", color: "inherit" },
              }}
            >
              {" "}
              <LinkText>Online</LinkText>
            </MenuButton>

            <MenuButton
              color="white"
              as="a"
              href="/profile"
              style={{
                paddingLeft: 20,
                textDecoration: "none",
                fontSize: "10",
                color: "inherit",
                "&:hover": { textDecoration: "none", color: "inherit" },
              }}
            >
              {" "}
              <LinkText>Profile</LinkText>
            </MenuButton>

            <MenuButton
              color="white"
              as="a"
              onClick={handleLogout}
              style={{
                paddingLeft: 20,
                textDecoration: "none",
                fontSize: "10",
                color: "inherit",
                "&:hover": { textDecoration: "none", color: "inherit" },
              }}
            >
              {" "}
              <LinkText>Logout</LinkText>
            </MenuButton>
          </Menu>
        </Stack>
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent overflow="scroll">
            <DrawerCloseButton />
            <DrawerHeader>
              {userInfo && userInfo.class === 1 && (
                <LinkText color="black">Admin: {userInfo.username}</LinkText>
              )}
              {userInfo && userInfo.class === 7 && (
                <LinkText color="black">
                  Financial: {userInfo.username}
                </LinkText>
              )}
              {userInfo && userInfo.class === 2 && (
                <LinkText color="black">Staff: {userInfo.username}</LinkText>
              )}
            </DrawerHeader>

            <DrawerBody>
              <VStack spacing={10} align="center" justify="center" pt={4}>
                {userInfo && (userInfo.class === 1 || userInfo.class === 7) && (
                  <AdminManageMenu
                    color="black"
                    classlevel={userInfo.class}
                    onClick={onClose}
                  />
                )}
                {userInfo && userInfo.class === 2 && (
                  <StaffManageMenus
                    Customer={userInfo}
                    CryoCommitteeList={CryoEmailList}
                    color="black"
                    onClick={onClose}
                  />
                )}

                {userInfo && (
                  <RecordMenus
                    color="black"
                    classLevel={userInfo.class}
                    onClick={onClose}
                  />
                )}

                {userInfo && (
                  <EmailMenus
                    color="black"
                    classlevel={userInfo.class}
                    onClick={onClose}
                  />
                )}

                <PricingMenu color="black" onClick={onClose} />

                <TrainingMenus color="black" onClick={onClose} />

                <Menu preventOverflow="true">
                  <MenuButton
                    as="a"
                    href="/calendar"
                    style={{
                      paddingLeft: 20,
                      textDecoration: "none",
                      fontSize: "10",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    {" "}
                    <LinkText color="black">Calendar</LinkText>
                  </MenuButton>

                  <MenuButton
                    as="a"
                    href="/online"
                    style={{
                      paddingLeft: 20,
                      textDecoration: "none",
                      fontSize: "10",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    {" "}
                    <LinkText color="black">Online</LinkText>
                  </MenuButton>

                  <MenuButton
                    as="a"
                    href="/profile"
                    style={{
                      paddingLeft: 20,
                      textDecoration: "none",
                      fontSize: "10",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    {" "}
                    <LinkText color="black">Profile</LinkText>
                  </MenuButton>

                  <MenuButton
                    as="a"
                    onClick={handleLogout}
                    style={{
                      paddingLeft: 20,
                      textDecoration: "none",
                      fontSize: "10",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    {" "}
                    <LinkText color="black">Logout</LinkText>
                  </MenuButton>
                </Menu>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
};

export default AdminClimsNavigationBar;
