import {
  Flex,
  Link,
  Stack,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  IconButton,
  Image,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "./menu-icon";

const LinkText = ({ children, color, ...props }) => {
  const colorValue = color || "white";
  return (
    <Text
      as="span"
      fontSize={["lg", "lg", "md"]}
      fontWeight="bold"
      color={colorValue}
      {...props}
    >
      {children}
    </Text>
  );
};

const LinkItem = ({ to, children, color = "white", onClick }) => {
  return (
    <Link as={RouterLink} to={to} color={color} onClick={onClick}>
      {children}
    </Link>
  );
};

const LinkMenuItem = ({ to, children, color = "black", onClick }) => {
  return (
    <MenuItem as={RouterLink} to={to} color={color} onClick={onClick}>
      <LinkText color={color}>{children}</LinkText>
    </MenuItem>
  );
};

const AboutUsMenu = ({ color = "white", onClick }) => (
  <Menu>
    <MenuButton>
      <LinkText color={color}>About Us</LinkText>
    </MenuButton>
    <MenuList>
      <LinkMenuItem onClick={onClick} to="/about/mission">
        Mission
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/about/publications">
        Publications
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/about/policy">
        Policy
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/about/equipment-donation">
        Equipment Donation
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/about/career">
        Career
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/about/visit-us">
        Visit Us
      </LinkMenuItem>
    </MenuList>
  </Menu>
);

const PeopleMenu = ({ color = "white", onClick }) => (
  <Menu>
    <MenuButton>
      <LinkText color={color}>People</LinkText>
    </MenuButton>
    <MenuList>
      <LinkMenuItem onClick={onClick} to="/people/staff">
        Professional Staff
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/people/committee">
        Advisory Committee
      </LinkMenuItem>
    </MenuList>
  </Menu>
);

const InstrumentsMenu = ({ color = "white", onClick }) => (
  <Menu>
    <MenuButton>
      <LinkText color={color}>Resources</LinkText>
    </MenuButton>
    <MenuList>
      <LinkMenuItem onClick={onClick} to="/resources/microscope">
        Microscopy
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/resources/x-ray">
        X-Ray
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/resources/sample-preparation">
        Sample Preparation
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/resources/availability">
        Availability
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/resources/pricing">
        Pricing
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/resources/cni-services">
        Capabilities
      </LinkMenuItem>
    </MenuList>
  </Menu>
);

const SupportMenu = ({ color = "white", onClick }) => (
  <Menu>
    <MenuButton>
      <LinkText color={color}>Support</LinkText>
    </MenuButton>
    <MenuList>
      <LinkMenuItem onClick={onClick} to="/sample-submission">
        Sample Submission
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/cryo-em-access-request">
        Cryo EM Access Request
      </LinkMenuItem>
      <LinkMenuItem onClick={onClick} to="/highres-em-access-request">
        High Resolution EM Access Request
      </LinkMenuItem>
    </MenuList>
  </Menu>
);

const NavigationBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  useEffect(() => {
    // Add padding to the body to account for the fixed navbar
    document.body.style.paddingTop = "60px"; // Adjust this value based on your navbar height

    // Cleanup function to remove padding when the component unmounts
    return () => {
      document.body.style.paddingTop = "0px";
    };
  }, []);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      // wrap="wrap"
      w="100%"
      mb={2}
      p={2}
      position="fixed"
      top={0}
      left={0}
      bg="black"
      borderTopStyle="solid"
      borderTopColor="red.600"
      borderTopWidth={[0, 0, 2, 2]}
      zIndex={1000}
      h="80px"
    >
      <Box
        display={{ base: "block", md: "none" }}
        position="relative"
        pl={{ base: "1.5rem", md: "2rem" }}
      >
        <IconButton
          aria-label={isOpen ? "Close" : "Open"}
          variant="ghost"
          onClick={onOpen}
          ref={btnRef}
          icon={<MenuIcon isOpened={isOpen} />}
          left={1}
        />
      </Box>
      <Box
        flex={{ base: 1, md: "initial" }}
        textAlign={{ base: "center", md: "left" }}
        pr={5}
      >
        <Link href="https://usc.edu" isExternal>
          <Image
            src={`${process.env.REACT_APP_IMAGES_URL}/home_images/USC_CNI_with_shield_large_gold.png`}
            alt="University of Southern California"
            width="100%"
            height="62px"
            objectFit={{ base: "contain", md: "contain" }}
            mb={2}
          />
        </Link>
      </Box>
      <Box
        display={{ base: "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
        h={["100vh", "100vh", "auto"]}
        mx="auto"
      >
        <Stack
          spacing={[10, 10, 16, 16]}
          align="center"
          justify={["center", "center", "center", "center"]}
          direction={["column", "column", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          <LinkItem to="/">
            <LinkText>Home</LinkText>
          </LinkItem>

          <AboutUsMenu />

          <PeopleMenu />

          <InstrumentsMenu />

          <LinkItem as={RouterLink} to="/login">
            <LinkText>CLIMS Login</LinkText>
          </LinkItem>

          <LinkItem as={RouterLink} to="/new-user">
            <LinkText>New User</LinkText>
          </LinkItem>

          <SupportMenu />
        </Stack>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent overflow="scroll">
            <DrawerCloseButton />
            <DrawerHeader>Menus</DrawerHeader>

            <DrawerBody>
              <VStack spacing={10} align="center" justify="center" pt={4}>
                <LinkItem to="/">
                  <LinkText onClick={onClose} color="black">
                    Home
                  </LinkText>
                </LinkItem>

                <AboutUsMenu color="black" onClick={onClose} />

                <PeopleMenu color="black" onClick={onClose} />

                <InstrumentsMenu color="black" onClick={onClose} />

                <LinkItem as={RouterLink} to="/login">
                  <LinkText onClick={onClose} color="black">
                    CLIMS Login
                  </LinkText>
                </LinkItem>

                <LinkItem as={RouterLink} to="/new-user">
                  <LinkText onClick={onClose} color="black">
                    New User
                  </LinkText>
                </LinkItem>

                <SupportMenu color="black" onClick={onClose} />
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
};

export default NavigationBar;
